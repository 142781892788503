import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastMessageService } from '@Services/toast-message/toast-message.service';
import { DeleteDialogComponent } from '@shared/components/dialogs/delete-dialog/delete-dialog.component';
import { ToastNotificationSeverity } from '@shared/enums';
import {
  DeleteDialogData,
  DeleteDialogServiceData,
} from '@shared/interfaces/delete-dialog.interface';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, Subscription } from 'rxjs';

/**
 * Service for handling delete dialog functionality.
 */
@Injectable({
  providedIn: 'root',
})
/**
 * Service for managing delete dialogs.
 */
export class DeleteDialogService {
  subscription: Subscription;

  ref: DynamicDialogRef | undefined;

  /**
   * Constructs the DeleteDialogService.
   * @param dialogService The DialogService from PrimeNG.
   * @param toastMessageService The ToastMessageService for displaying toast messages.
   * @param httpClient The HttpClient for making HTTP requests.
   */
  constructor(
    private dialogService: DialogService,
    private toastMessageService: ToastMessageService,
    private httpClient: HttpClient,
  ) {
    this.subscription = new Subscription();
  }

  /**
   * Opens the delete dialog.
   * @param deleteDialogDetails The details for the delete dialog.
   * @param entityName The name of the entity being deleted.
   * @param successCB The callback function to be called when the delete is successful.
   */
  openDeleteDialog(
    deleteDialogDetails: DeleteDialogServiceData,
    entityName: string,
    successCB: () => void,
  ): void {
    this.ref = this.dialogService.open(DeleteDialogComponent, {
      showHeader: false,
      closable: false,
      width: '50%',
      style: { 'max-width': '500px', 'min-width': '300px' },
      data: {
        headerText: deleteDialogDetails.headerText,
        itemName: deleteDialogDetails.name,
        descriptionText: deleteDialogDetails.description,
        deleteCallback: this.getDeleteCallback(successCB, entityName, deleteDialogDetails),
      } as DeleteDialogData,
    });
  }

  /**
   * Returns the delete callback function.
   * @param successCB The callback function to be called when the delete is successful.
   * @param entityName The name of the entity being deleted.
   * @param deleteDetails The details for the delete operation.
   * @returns The delete callback function.
   */
  getDeleteCallback(
    successCB: () => void,
    entityName: string,
    deleteDetails: DeleteDialogServiceData,
  ): (reason: string) => Promise<void> {
    return (reason: string) => {
      return new Promise<void>((resolve, reject) => {
        this.subscription.add(
          this.deleteData(deleteDetails.path, reason).subscribe({
            next: () => {
              this.toastMessageService.emitMessage({
                severity: ToastNotificationSeverity.SUCCESS,
                summary: 'Success',
                details: `Successfully deleted ${entityName} ${deleteDetails.name}.`,
              });
              successCB(); // to inform parent component that the delete was successful
              resolve(); // to inform the dialog that the delete was successful
            },
            error: (httpError: HttpErrorResponse) => {
              this.toastMessageService.showValidationErrors(
                httpError.error,
                httpError?.error?.error_message ?? `Unable to delete ${entityName}.`,
              );
              reject(new Error('')); // to inform the dialog that the archive was unsuccessful
            },
          }),
        );
      });
    };
  }

  /**
   * Delete the data by making an HTTP DELETE request.
   * @param path The path for the delete operation.
   * @param reason The reason for the delete operation.
   * @returns An Observable that represents the HTTP DELETE request.
   */
  deleteData(path: string, reason: string): Observable<unknown> {
    return this.httpClient.delete(`${path}?reason=${reason}`);
  }
}
