import { Component, OnInit } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { PageTitleComponent } from '../../page-title/page-title.component';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTemplateComponent } from '@shared/form-inputs/input-template/input-template.component';
import { InputTextareaModule } from 'primeng/inputtextarea';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DeleteDialogData } from '@shared/interfaces/delete-dialog.interface';
import { SkeletonModule } from 'primeng/skeleton';
import { DialogModule } from 'primeng/dialog';

@Component({
  selector: 'app-delete-dialog',
  standalone: true,
  imports: [
    ButtonModule,
    PageTitleComponent,
    InputTemplateComponent,
    InputTextareaModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SkeletonModule,
    DialogModule,
  ],
  templateUrl: './delete-dialog.component.html',
  styleUrl: './delete-dialog.component.scss',
})
export class DeleteDialogComponent implements OnInit {
  headerText: string | null = null;

  itemName: string | null = null;

  descriptionText: string | null = null;

  isDeleteInProgress = false;

  deleteForm: FormGroup;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig<DeleteDialogData>,
  ) {}

  ngOnInit(): void {
    this.deleteForm = new FormGroup({
      description: new FormControl(null, [Validators.required]),
    });

    this.headerText = this.config.data?.headerText ?? null;
    this.itemName = this.config.data?.itemName ?? null;
    this.descriptionText = this.config.data?.descriptionText ?? null;
  }

  onSubmit() {
    if (this.deleteForm.invalid) {
      this.deleteForm.markAllAsTouched();
      return;
    }
    this.isDeleteInProgress = true;
    this.deleteForm.disable();
    this.config.data
      ?.deleteCallback(this.deleteForm.value.description)
      .then(() => {
        this.isDeleteInProgress = false;
        this.deleteForm.enable();
        this.ref?.close();
      })
      .catch(() => {
        this.deleteForm.enable();
        this.isDeleteInProgress = false;
      });
  }

  closeDialog() {
    this.ref?.close();
  }
}
